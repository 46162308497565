import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Nav from "./nav"

const Header = ({ siteTitle }) => (
  <HeaderMenu>
    <Nav />
    <Link to="/">kupisliku.com</Link>
  </HeaderMenu>
)

export default Header

const HeaderMenu = styled.header`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  height: 70px;
  padding-bottom: 20px;
  > button {
    margin-left: 10px;
  }
  a {
    font-size: 24px;
    margin-left: 10px;
  }
`
