import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open}>
      <Link to="/" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="početna"></span>
        Početna
      </Link>
      <Link to="/#kategorije" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="kategorije"></span>
        Kategorije
      </Link>
      <Link
        to="/slike-na-platnu/aktuelno"
        href="/"
        onClick={() => setOpen(!open)}
      >
        <span role="img" aria-label="aktuelno"></span>
        Aktuelno
      </Link>
      <Link to="/cart" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="korpa"></span>
        Korpa
      </Link>
    </StyledMenu>
  )
}

export default Menu

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #eee;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  z-index: 10;

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ##4881d7;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      opacity: 0.9;
    }
  }
`
