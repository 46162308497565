import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { CartContext } from "../../context/cartContext"
import CartIcon from "../../images/svg/cart.svg"

const CartButton = ({ count }) => {
  const [cart] = useContext(CartContext)

  return (
    <Cart to="/cart">
      <CartIcon />
      <span>{cart.length}</span>
    </Cart>
  )
}

export default CartButton

const Cart = styled(Link)`
  margin: 10px;
  position: fixed;
  top: 0px;
  right: 20px;
  z-index: 10;
  svg {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
    height: 30px;
    width: 30px;
    padding: 20px;
    transition: all 0.3s;
    :hover {
      fill: #707070;
    }
  }
  span {
    background-color: #f05e5e;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    height: 8px;
    width: 8px;
    position: absolute;
    right: 8px;
    top: 15px;
  }
`
