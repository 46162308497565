import React, { useState } from "react"

import Burger from "./Burger"
import Menu from "./Menu"

const Nav = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </>
  )
}

export default Nav
