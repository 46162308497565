import React from "react"
import { createGlobalStyle } from "styled-components"

import Header from "./header"
import CartButton from "./shared/cartButton"
import MessengerCustomerChat from "react-messenger-customer-chat"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <CartButton count={0} />
      <main>{children}</main>
      <MessengerCustomerChat
        pageId="112994023748552"
        appId=""
        htmlRef="fb-customerchat"
      />
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F1F1F0;
    color: #434343;
    font-family: 'Source Sans Pro', 'sans-serif'
  }
  a {
    color: #4881D7;
    text-decoration: none;
    :hover {
      opacity: 0.95;
    }
  }
  .canvas-type-selected {
    box-shadow: 0 0 0 2px #505050, 8px 8px 16px rgba(0, 0, 0, 0.1);
  }
`

export default Layout
